import React from "react"
import { graphql } from "gatsby"

import { GatsbyProps } from "Src/global-types"

import StandardLayout from "Components/core/StandardLayout"
import SEO from "Components/core/SEO"

import Carousel, { CarouselImages } from "Components/Carousel"

interface HexagonCloneProps {
  data: CarouselImages
}

const HexagonClone: React.FC<HexagonCloneProps & GatsbyProps> = ({
  data: { smallestImages, smallImages, mediumImages, largeImages },
  location,
}) => (
  <StandardLayout location={location}>
    <SEO title="Hexagon Clone" />
    <h1>Hexagon Clone</h1>
    <p>
      For final programming project I worked as part of a two man team to create
      a game, the creation of the game was inspired by Super Hexagon, except we
      tried to very up the idea by having the player shoot an area that’s size
      was randomly determined. We also added a second game mode that required
      the use of two different lasers.
    </p>
    <p>
      The ended up running on my phone in the end. And is technically still
      playable. If you want to have a go yourself you can{" "}
      <a href="https://github.com/LiamMyles/Prototype-SuperHexagonLike-2014">
        checkout project code on GitHub.
      </a>
    </p>
    <Carousel
      smallestImages={smallestImages}
      smallImages={smallImages}
      mediumImages={mediumImages}
      largeImages={largeImages}
      altTexts={[
        "Shield with twin swords",
        "Twin Swords on Altar",
        "Swords on alter with shield",
      ]}
    />
  </StandardLayout>
)

export default HexagonClone

export const query = graphql`
  query {
    smallestImages: allFile(
      filter: {
        relativePath: { glob: "processing/super-hexagon-clone-merge*" }
      }
    ) {
      edges {
        node {
          ...CarouselSmallestImage
        }
      }
    }
    smallImages: allFile(
      filter: {
        relativePath: { glob: "processing/super-hexagon-clone-merge*" }
      }
    ) {
      edges {
        node {
          ...CarouselSmallImage
        }
      }
    }
    mediumImages: allFile(
      filter: {
        relativePath: { glob: "processing/super-hexagon-clone-merge*" }
      }
    ) {
      edges {
        node {
          ...CarouselMediumImage
        }
      }
    }
    largeImages: allFile(
      filter: {
        relativePath: { glob: "processing/super-hexagon-clone-merge*" }
      }
    ) {
      edges {
        node {
          ...CarouselLargeImage
        }
      }
    }
  }
`
